import { Box } from "@mui/material";
import React from "react";
import { Assistant } from "../components/assistant-layout";

export const AiTourPage: React.FC = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflow: "hidden",
				height: "calc(100vh - 112px)",
				maxWidth: "100vw",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					contain: "content",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					border: "none",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
						width: "850px",
					}}
				>
					<iframe
						src="https://pluenligne.paris.fr/plu/sites-plu/site_statique_55/pages/page_1175.html"
						height="100%"
						width={"100%"}
					></iframe>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
						width: "calc(100% - 850px)",
						m: "10px",
						p: "5px",
						border: "1px solid #e0e0e0",
						borderRadius: "5px",
					}}
				>
					<Assistant mode={"web"} />
				</Box>
			</Box>
		</Box>
	);
};
